import request from '@/utils/request'

export const getTestPaperGroupList = (params: {
  gradeNo?: string,
  grade?: string,
  termNo?: string,
  term?: string,
  term02?: string,
  pageSize: number,
  curPage: number,
  testPaperIds?: string[],
  resourceTemplateId?: string
}) =>
  request({
    // 获取试卷库列表
    url: '/v1/TestPaperGroupList',
    method: 'GET',
    params
  })

  export const getTestPaperGroupCascadeList = (params: { schoolId?: string }) => request({
    //  获取试卷分组级联列表
    url: '/v1/TestPaperGroupCascadeList',
    method: 'GET',
    params
  })

export const getTestPaperList = (params: {
  schoolId?: string,
  term02?: string,
  term?: string,
  gradeNo: string,
  keyWords: string,
  pageSize: number,
  curPage: number,
  testPaperIds?: string[]
}) =>
  request({
    // 获取试卷库列表
    url: '/v1/TestPaperList',
    method: 'GET',
    params
  })
export const getTestPaper = (testPaperId: string) =>
  request({
    // 获取试卷库
    url: `/v1/TestPaper/${testPaperId}`,
    method: 'GET',
  })
export const delTestPaper = (testPaperId: string) =>
  request({
    // 删除试卷库
    url: `/v1/TestPaper/${testPaperId}`,
    method: 'DELETE'
  })
export const setTestPaper = (data: any) =>
  request({
    url: `/v1/TestPaper`,
    method: 'POST',
    data
  })
export const listForTestPaper = (data: { testPaperFileName: string }) =>
  request({
    // 获取试卷标准答案列表
    url: '/v1/standardAnswer/listForTestPaper',
    method: 'GET',
    params: data
  })
export const updateForTestPaper = (data: { testPaperFileName: string }) =>
  request({
    // 创建试卷标答记录
    url: '/v1/standardAnswer/updateForTestPaper',
    method: 'POST',
    data
  })
export const copyForTestPaper = (data: { fromTestPaperFileName: string, toTestPaperFileName: string }) =>
  request({
    // 复制试卷标答记录
    url: '/v1/standardAnswer/copyForTestPaper',
    method: 'POST',
    data
  })

export const standardAnswer = (data: any) =>
  request({
    // 添加标准答案
    url: '/v1/standardAnswer',
    method: 'POST',
    data
  })

/**
 * 获取试卷授权列表
 * @param data 
 * @returns 
 */
export const getTestPaperUseAuthList = (params: {
  pageSize: number,
  curPage: number,
  testPaperId?: string,
  testPaperIds?: string[],
  schoolId?: string
}) =>
  request({
    url: '/v1/TestPaperUseAuthList',
    method: 'GET',
    params
  })

/**
 * 获取试卷授权
 * @param testPaperUseAuthId 
 * @returns 
 */
export const getTestPaperUseAuth = (testPaperUseAuthId: string) =>
  request({
    url: `/v1/TestPaperUseAuth/${testPaperUseAuthId}`,
    method: 'GET',
  })
/**
 * 删除试卷授权
 * @param testPaperUseAuthId 
 * @returns 
 */
export const delTestPaperUseAuth = (testPaperUseAuthId: string) =>
  request({
    url: `/v1/TestPaperUseAuth/${testPaperUseAuthId}`,
    method: 'DELETE'
  })

/**
 * 保存试卷授权
 * @param data 
 * @returns 
 */
export const postTestPaperUseAuth = (data: any) =>
  request({
    url: '/v1/TestPaperUseAuth',
    method: 'POST',
    data
  })

export const getTestPaperGroupGradeOptionList = () =>
  request({
    // 获取试卷库分组年级选项列表
    url: '/v1/TestPaperGroupGradeOptionList',
    method: 'GET'
  })

export const getResourceTemplateList = (params: {
  resourceTemplateId?: string,
  resourceTemplateName?: string,
  keyWords?: string,
  pageSize: number,
  curPage: number,
  resourceTemplateIds?: string[]
}) =>
  request({
    // 获取试卷库列表
    url: '/v1/ResourceTemplateList',
    method: 'GET',
    params
  })
export const getResourceTemplate = (resourceTemplateId: string) =>
  request({
    // 获取试卷库
    url: `/v1/ResourceTemplate/${resourceTemplateId}`,
    method: 'GET',
  })
export const delResourceTemplate = (resourceTemplateId: string) =>
  request({
    // 删除试卷库
    url: `/v1/ResourceTemplate/${resourceTemplateId}`,
    method: 'DELETE'
  })
export const setResourceTemplate = (data: any) =>
  request({
    url: `/v1/ResourceTemplate`,
    method: 'POST',
    data
  })

export const getResourceTemplateTestPaperUseAuthList = (params: {
  schoolId?: string,
  pageSize: number,
  curPage: number,
  schoolIds?: string[],
  testPaperIds?: string[]
}) =>
  request({
    // 获取试卷库列表
    url: '/v1/ResourceTemplateTestPaperUseAuthList',
    method: 'GET',
    params
  })

export const getResourceTemplateProfile = (resourceTemplateId: string) =>
  request({
    // 获取试卷库
    url: `/v1/ResourceTemplateProfile/${resourceTemplateId}`,
    method: 'GET',
  })
export const setResourceTemplateProfile = (data: any) =>
  request({
    url: `/v1/ResourceTemplateProfile`,
    method: 'POST',
    data
  })

export const delResourceTemplateProfile = (resourceTemplateId: string) =>
  request({
    // 删除试卷库
    url: `/v1/ResourceTemplateProfile/${resourceTemplateId}`,
    method: 'DELETE'
  })